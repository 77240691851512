<template>
  <section id="popInvite" class="popup popInvite profile">
    <div class="popBody">
      <a class="close"
        ><font-awesome-icon icon="fas fa-times"></font-awesome-icon
      ></a>
      <section class="popHeader">
        <span class="fL primary">推薦賺雲林幣</span>
      </section>
      <section  class="popMain">

          嗨～我的好朋友，現在下載雲林幣 App, 賺雲林幣換取豐富商品!
        <br>記得註冊時輸入我的代碼: {{ user?.user?.myReferralCode }}
          <br />下載連結: https://yunlincoin.yunlin.gov.tw/DownloadNubi/Download <br />每成功推薦一位朋友,立即獲得 {{ point }} 枚雲林幣！
          <!-- <div class="flexV">
                          <span class="fM">成功邀請朋友</span>
                          <span class="fL third">10</span>
                      </div> -->
          <button
            class="button rounded border-0 text-white mt-4"
            :class="{'bg-success': isCopied}"
            @click.prevent="copy">
            {{ isCopied ? '已複製' : '點我複製內文'}}
          </button>
      </section>
      <!-- <section class="popFooter">
        <a class="button rounded code white" @click.prevent="copy()">
          <div class="flexH">
            <div class="icon">
              <font-awesome-icon icon="fa-solid fa-check"></font-awesome-icon>
            </div>
            <span v-show="isCopied" class="fS gray">已複製</span>
          </div>
          <span class="fM">{{ displayReferralCode() }}</span>
          <input
            type="hidden"
            id="referral-code"
            :value="generateReferralCode()"
          />
        </a>
        <a class="button rounded line" :href="shareWithLINEUrl()">
          <div class="img">
            <img src="@/assets/icon/line.svg" />
          </div>
          <span class="fM">Line邀請好友</span>
        </a>
      </section> -->
    </div>
  </section>
</template>

<script>
import { mapState } from "vuex";

export default {
  data() {
    return {
      apiHost: process.env.VUE_APP_API_HOST,
      merchantId: process.env.VUE_APP_MERCHANT_ID,
      isCopied: false,
      campaigns: [],
      referralCampaign: {}
    }
  },
  computed: {
    ...mapState(["user", "userInfo"]),
    point() {
      return this.referralCampaign?.reward_point || 50;
    }
  },
  created() {
    this.getCampaigns();
  },
  methods: {
    getCampaigns() {
      this.getCampaignAPI()
        .then((res) => {
          this.campaigns = res.data.campaigns;
          this.campaigns.forEach((campaign) => {
            if (campaign.campaignPrototype === "referral-referrer") {
              this.referralCampaign = campaign;
            }
          });

        })
        .catch((err) => {
          console.log('getCampaigns', err);
        });
    },
    getCampaignAPI() {
      let config = {
        url: `${this.apiHost}/campaigns/v1/merchants/${this.merchantId}/participations/uuid/${this.user.userId}/member_id/${this.userInfo.memberId}?includeNonJoined=true&includeInactive=true&publisherType=municipal`,
        methods: "GET"
      };
      return this.$http(config);
    },
    openPop() {
      var popInvite = document.getElementById("popInvite");
      var close = popInvite.getElementsByClassName("close")[0];
      close.onclick = function () {
        popClose();
      };
      window.onclick = function (event) {
        if (event.target == popInvite) {
          popClose();
        }
      };
      function popClose() {
        popInvite.style.display = "none";
        document.body.style.overflow = "scroll";
      }
      popInvite.style.display = "flex";
      document.body.style.overflow = "hidden";
    },
    copy() {
      navigator.clipboard.writeText(`嗨～我的好朋友，現在下載雲林幣 App, 賺雲林幣換取豐富商品!\n記得註冊時輸入我的代碼: ${this.user?.user?.myReferralCode}\n下載連結: https://yunlincoin.yunlin.gov.tw/DownloadNubi/Download \n每成功推薦一位朋友,立即獲得 ${this.point} 枚雲林幣！`)
      var successful = document.execCommand("copy");
      if (successful) {
        this.isCopied = true;
        setTimeout(() => {
          this.isCopied = false
        }, 1000);
      }
    },
  }
}
</script>